:root {
  --black: #09090C;
  --white: #FFFFFF;
  --purple: #513CCC;
  --grey: #B9B9B1;
  --neutral: #B8BEC9;
  --primary: #8D2424;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
}

a{
  text-decoration: none !important;
}

.container-fluid{
  max-width: 1140px;
}

.scroll-to-top{
  border-radius: 50%;

  svg{
    width: 18px;
    height: 18px;
    fill: var(--primary);
  }
}

.locale-container{
  position: absolute;
  top: 0;
  right: 0;
}

.text-primary{
  font-weight: bold;
  color: var(--primary) !important;
}

.btn-primary{
  background-color: #8D2424 !important;
  // font-size: 18px !important;
  // line-height: 55px !important;
  // height: 55px;
  padding: 1rem 2rem !important;
  border-radius: 28px !important;
  border: none !important;
  box-shadow: none !important;
  color: white;
  transition: background 0.3s ease;

  &:hover{
    background: darken(#8D2424, 5%) !important;
  }
}

.btn-outline-primary{
  // background-color: #8D2424 !important;
  // font-size: 18px !important;
  // line-height: 55px !important;
  // height: 55px;
  padding: 1rem 2rem !important;
  border-radius: 28px !important;
  border: 1px solid #8D2424 !important;
  box-shadow: none !important;
  color: #8D2424;
  transition: background 0.3s ease;

  &:hover{
    background: darken(#8D2424, 5%) !important;
  }
}

.btn-outline-dark{
  color: var(--white);
  border-color: var(--white);
  
  a{
    text-decoration: none;
    color: white;
  }

  &:hover{
    border-color: var(--white);
  }
}

.accordion{
  border-top: 1px solid #e2e2e2;
  
  &-item{
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #e2e2e2;
    display: block;
    outline: none;
    box-shadow: none;
    line-height: 1.2;
  }
  
  &-button{
    font-size: 20px;
    padding: 1rem 0 !important;
    transition: all 0.3s ease;
    &::after{
      font-size: 24px;
    }
    &:not(.collapsed){
      color: var(--primary) !important;
      background-color: transparent !important;
      font-weight: bold;
    }
    &:focus{
      border: none !important;
      box-shadow: none !important;
    }
  }
}

.dropdown-menu{
  box-shadow: 2px 4px 20px 0 rgba(34, 90, 8, 0.2);
  border: transparent !important;

  .dropdown-item{
    >a{
      color: var(--black) !important;
      text-decoration: none !important;

      &:focus,
      &:hover{
        background-color: white !important;
        color: var(--primary) !important;
        transition: all 0.3s ease;
      }
    }
    &:focus,
      &:hover{
        background-color: white !important;
        color: var(--primary) !important;
        transition: all 0.3s ease;
    }
  }

  // .dropdown-item:focus, 
  // .dropdown-item:hover{
  //   background-color: white !important;
  //   color: var(--primary) !important;
  //   transition: all 0.3s ease;
  // }
}

.navbar{
  margin-bottom: 1rem; 

  &-collapse{
    text-align: right;
  }

  &-toggler{
    border: none !important;
    box-shadow: none !important;

    &:active,
    &:focus{
      border: none !important;
    }
  
    &-icon{
      display: none !important;
    }
  }

  .nav-link{
    color: #000 !important;

    &:hover{
      color: var(--primary) !important;
      transition: all 0.3s ease;
    }

    @media (min-width: 767px) {
      margin: auto 2rem auto 0;
    }
  }

  .nav-item{
    display: inline-block !important;
  }

  .hamburger-menu{
    position: relative;
    display: block;
    width: 38px;
    height: 38px;
    cursor: pointer;
    z-index: 99;
  
    &.hamburger-menu-active{
      .hamburger-menu-wrapper{
        span{
          &:nth-child(1){
            transform: translateY(13px) rotate(-45deg);
          }
  
          &:nth-child(2){
            opacity: 0;
          }
  
          &:nth-child(3){
            transform: translateY(-13px) rotate(45deg);
          }
        }
      }
    }
  
    &-wrapper{
      height: 200%;
      box-sizing: border-box;
      background: transparent;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 24px 0;
      transform: scale(0.5);
      transform-origin: top;
  
      span{
        transition: opacity 0.3s, transform 0.3s, background-color 0.3s;
        box-sizing: border-box;
        height: 3px;
        border-radius: 4px;
        background-color: #000;
      }
    }
  }
}

.locale-buttons{
  margin-right: .5rem;
  font-size: 12px;
  height: 16px;

  .form-check{
    padding-left: 0 !important;
  
    &:first-child{
      &:after{
        content: "|";
      }
    }
  
    label{
      cursor: pointer;
      margin: 0 .5rem;
    }
  
    &-inline{
      margin: 0 !important;
    }
    
    &-input{
      display: none;
      
      &:checked,
      &:hover{
        ~ label{
          color: var(--primary);
          font-weight: bold;
          transition: all 0.3s ease;
        }
      }
    }
  }
}

.regi-form{
  height: 500px;
}