.navbar{
  background: white;
  border-bottom: 1px solid #d2d2d2;

  .nav-item{
    align-items: center !important;
    // margin: auto 2rem auto 0;
    color: black;
    transition: all 0.3s ease;
    text-align: right;

    @media (min-width: 767px) {
      margin: auto 2rem auto 0;
    }
    
    &:hover{
      color: #8D2424;
    }

    & > a{
      color: black;

      &:hover{
        color: #8D2424
      }
    }
  }
  

}