.home{
  &-hero{
    background-color: #F9EFEC;
    position: relative;

    img{
      @media (min-width: 767px) {
        padding: 4rem;
      }
    }

    .arrow{
      text-align: center;
      padding: 3rem 0;
    }

    .text-wrapper{
      padding: 3rem;
      color: #f8f8f8;
      background-color: #431A20;
      max-width: 550px;
      @media (min-width: 767px) {
        position: absolute;
        transform: translateY(-100%);
        right: 0;
      }

      .heading{
        font-size: 3.5em;
        font-weight: bold;
        line-height: 1.2;
        // margin-bottom: 2rem;
        @media (max-width: 767px) {
          font-size: 2em;
        }
      }

      .desc{
        font-size: 1em;
        margin: 1rem 0;
      }
    }
  }

  &-about{
    font-size: 2rem;
    font-weight: bold;
    line-height: 1.3;
    margin: 5rem 2rem;
    @media (max-width: 767px) {
      margin: 3rem 2rem;
      font-size: 1.5rem;
    }

    .heading{
      color: var(--primary);
      float: left;
      width: 10;
      padding-right: 30px;
      margin-bottom: 0;
    }
  }

  &-intro{
    font-size: 1rem;
    // font-weight: bold;
    line-height: 1.3;
    margin: 5rem 4rem;
  }

  &-about-us{
    font-size: 20px;
    margin: 2rem 0;

    .desc{
      margin-bottom: 4rem;
      line-height: 2;
    }
  }

  &-advisory-board,
  &-exco,
  &-milestones{
    margin-bottom: 4rem;

    .title{
      font-size: 30px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 2rem;
      color: var(--primary);
    }
  }

  .timeline{
    &-item{
      position: relative;
      padding: 2rem 0;

      .desc{
        box-shadow: 2px 4px 20px 0 rgba(34, 90, 8, 0.2);
        border: transparent !important;
        padding: 20px;
        margin: 0 5rem 0 2rem;
        border-radius: 20px;
        white-space: pre-wrap;

      }

      .year{
        color: var(--primary);
        font-weight: bold;
        padding: 2rem;
        font-size: 20px;

        &-right{
          @media (min-width: 767px) {
            text-align: right;
          }
        }
      }
    }

    &-line{
      display: block;
      width: 1px;
      height: 100%;
      background: #D2D2D2;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      @media (max-width: 767px) {
        left: 0%;
      }
    }

    &-dot{
      display: block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: var(--primary);
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      @media (max-width: 767px) {
        left: 0%;
      }
    }
  }
}