.our-roots{
  margin-bottom: 4rem;

  .title{
    font-size: 3.5em;
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 2rem;
    color: var(--primary);
  }

  .heading{
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 2rem;
    color: var(--primary);
  }

  .desc{
    font-size: 20px;
    margin-bottom: 2rem;

    > p{
      margin-bottom: 2rem;
    }

    a{
      color: var(--primary);
    }
  }
}