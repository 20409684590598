.event{
  color: #431A20;

  .heading{
    font-size: 2.5em;
    font-weight: bold;
    line-height: 1.5;
    margin-bottom: 1rem;
    @media (max-width: 767px) {
      font-size: 2em;
    }
  }

  &-hero{
    position: relative;

    @media (max-width: 767px) {
      text-align: center;
    }

    img{
      @media (min-width: 767px) {
        padding: 2rem 0 2rem 4rem;
      }
    }

    .text-wrapper{
      padding: 3.5rem;
      background-color: #E8E6E6;
      max-width: 750px;
      
      @media (min-width: 767px) {
        position: absolute;
        transform: translateY(-100%);
      }


    .desc{
      margin-bottom: 2rem;
      line-height: 1.5;
    }
    }
  }

  &-about{
    font-size: 2rem;
    font-weight: bold;
    line-height: 1.3;
    margin: 3rem 2rem;
    padding: 2rem;
    @media (max-width: 767px) {
      margin: 1rem;
      font-size: 1.5rem;
    }

    .title{
      color: var(--primary);
      float: left;
      width: 10;
      padding-right: 30px;
      margin-bottom: 0;
    }
  }

  &-programme{
    margin-bottom: 100px;

    @media (max-width: 767px) {
      margin: 1rem;
      padding: 2rem;
    }

    .item-name{
      background-color: #E8E6E6;
      color: #431A20;
      font-size: 22px;
      font-weight: bold;
      text-align: center;
      padding: 4rem 1rem;
      margin: 1rem 0;
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }

  &-form{
    margin-bottom: 100px;

    @media (max-width: 767px) {
      margin: 1rem;
      padding: 2rem;
    }
  }
}