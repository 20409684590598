.contact{
  // margin-bottom: 4rem;
  @media (min-width: 767px) {
    // padding: 4rem;
    margin: 0 6rem 4rem 6rem;
  }

  .title{
    font-size: 3.5em;
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 2rem;
    color: var(--primary);
    @media (max-width: 767px) {
      font-size: 2.5rem;
    }
  }

  .heading{
    font-size: 30px;
    font-weight: bold;
    line-height: 1.2;
    color: var(--primary);
  }

  .form-section{
    border: 1px solid var(--primary);
    margin: 20px 0;

    .form{
      font-size: 20px;
      font-weight: bold;
      line-height: 1.2;
      color: var(--primary);
    }
    .desc{
      font-size: 20px;
      line-height: 1.2;
    }
  }
}