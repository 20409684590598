.events{
  margin-bottom: 4rem;

  .title{
    font-size: 3.5em;
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 2rem;
    color: var(--primary);
  }

  .heading{
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 2rem;
    color: var(--primary);
  }

  .name{
    font-weight: bold;
    font-size: 20px;
    color: var(--primary);
  }

  .date{
    font-weight: bold;
    font-size: 18px;
  }

  .desc{
    font-size: 18px;
    margin-bottom: 2rem;
  }
}