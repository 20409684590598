.footer{
  background-color: rgb(28,31,39);
  color: white;
  padding: 4rem 0;

  &-inner{
    text-align: center;

    ul{
      list-style: none;
      margin: 0;
      padding: 0;
      // display: flex;
  
      li{
        display: inline-block;
      }
    }

    &-wrapper{
      background: white;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      position: relative;
      margin: 0 4px;
      display: inline-block;
      transition: color 0.3s ease;

      &:hover{
        background: darken(white, 5%);
      }
      
      >a{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        text-align: center;
        
        .icon-wrapper{
          padding: 5%;
        }
      }
      
      >img{
        position: absolute;
        vertical-align: middle;
        top: 0;
        left: 0;
        width: 50%;
        height: 50%;
      }
    }
  }

  &-inner{
    max-width: 1800px;
    margin-left: auto;
    margin-right: auto;
  }

  &-nav{
    text-align: center;
    padding: 20px 0;
    letter-spacing: .2em;
    line-height: 2em;
    text-transform: uppercase;

    &-group{
      display: inline;
    }

    &-item{
      padding-right: 1em;
      display: inline-block;
    }
  }

  a{
    text-decoration: none;
    color: #fff;
    transition: color 0.3s ease;
    
    &:hover{
      color: darken(#fff, 10%)
    }


    svg{
      fill: white;
    }
  }
}